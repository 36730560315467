// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'


export const getAllTemplates = createAsyncThunk('appCampaigns/getAllTemplates', async (params) => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.get(`/customers/templates_all/`, {params: {search:params.channel, status: params.status}})
  return response
})

export const getTemplate = createAsyncThunk('appTemplates/getTemplate', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instance.get(`/customers/templates/${id}/`)
  return response.data
})

export const cleanTemplate = createAsyncThunk('appTemplates/cleanTemplate', () => {
  return "template"
})

export const getAllCustomers = createAsyncThunk('appCampaigns/getAllCustomers', async () => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.post(`/customers/customers/groupby_document/`)
  return response
})

export const addCampaignFrom = createAsyncThunk('appCampaigns/addCampaignFrom', async (params) => {
  const instanceAddCampaignFrom = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  instanceAddCampaignFrom.post('/campaigns/campaigns/create_from/', params.campaign)
    .then(async resp => {
      sucessToast("Campaña lanzada correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Campaña no fue lanzada correctamente")
      }
    })
})

const concatArraysLikeJson = (a, b) => {
  const result = a.map((e, i) => {
    return {
      id : e.subscription__customer__id,
      first_name: e.subscription__customer__first_name,
      last_name: e.subscription__customer__last_name,
      dni: e.subscription__customer__dni,
      tradename: e.subscription__customer__tradename,
      position: e.subscription__customer__position,
      phone: b[i].phone,
      email: b[i].email,
      saldo: e.saldo,
      docs: e.docs,
      contactos: b[i].numContacts
    } 
  })
  return result
}

export const appCreateCampaignSlice = createSlice({
  name: 'appCreateCampaign',
  initialState: {
    allTemplates: [],
    allCustomers: [],
    allContacts: [],
    allCustomersByDocuments: [],
    total: 1,
    selectedTemplate: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.allTemplates = action.payload.data
      })
      .addCase(cleanTemplate.fulfilled, (state) => {
        state.selectedTemplate = null
        state.total = 1
      })

      .addCase(getTemplate.fulfilled, (state, action) => {
        state.selectedTemplate = action.payload
      })

      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.allCustomers = action.payload.data.infoDocs
        state.allContacts = action.payload.data.contactos
        state.allCustomersByDocuments = 
          concatArraysLikeJson(state.allCustomers, state.allContacts)
      })

  }
})

export default appCreateCampaignSlice.reducer