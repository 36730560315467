// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

const qs = require('qs')
export const getAllData = createAsyncThunk('appCustomers/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/customers/`, {params: {p:params.page, search:params.query}})
  console.log(response)
  return response
})

export const getAllUsers = createAsyncThunk('appUsers/getAllUsers', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/users/`, {params: {p:params.page, search:params.query}})
  console.log(response)
  return response
})

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  const response = await instance.get(`/customers/customers/${id}/`)
  return response.data
})

export const getContact = createAsyncThunk('appCustomers/getContact', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })  
  const response = await instance.get(`/customers/contacts/${id}/`)
  return response.data
})

export const addContact = createAsyncThunk('appCustomers/addContact', (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })  
  instance.post(`/customers/contacts/`, params.contact)
    .then(async resp => {
      await dispatch(getCustomer(params.idCustomer))
      sucessToast("Contacto se añadio correctamente")
      console.log(resp.data)
      return resp.data
    })
    .catch(() => {
      errorToast("Contacto no se añadio correctamente")
    })
  
})

export const cleanContacts = createAsyncThunk('appCustomers/cleanContacts', () => {
  return "clean"
})

export const updateContact = createAsyncThunk('appCustomers/updateContact', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/contacts/${params.contact.id}/`, qs.stringify(params.contact))
    .then(async resp => {
      await dispatch(getCustomer(params.idCustomer))
      sucessToast("Contacto se modifico correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("Contacto no se modifico correctamente")
    })
  
})


export const deleteContact = createAsyncThunk('appCustomers/deleteContact', (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })  
  instance.delete(`/customers/contacts/${params.idContact}/`)
    .then(async resp => {
      await dispatch(getCustomer(params.idCustomer))
      sucessToast("Contacto se elimino correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("Contacto no se elimino correctamente")
    })
  
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (params, { dispatch }) => {
  console.log(params.customer)
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  instance.post('/customers/customers/', params.customer)
    .then(async resp => {
      await dispatch(getAllData({page:1, query:""}))
      sucessToast("Cliente añadido correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Cliente no fue creado correctamente")
      }
    })
  
})

// const getErrorMessage =  data => {
//   let resp = "Usuario no fue creado correctamente. "
//   for (const property in data) {
//     resp += data[property] + " \n"
//   }
  
//   return resp
// }

export const addUser = createAsyncThunk('appCustomers/addUser', async (params, { dispatch }) => {
  console.log(params.user)
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  instance.post('/users/', params.user)
    .then(async resp => {
      await dispatch(getAllUsers({page:1, query:""}))
      sucessToast("Usuario añadido correctamente")
      return resp.data
    })
    .catch((e) => {
      console.log(e.response.data)
      errorToast(e.response.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    })
  
})

export const updateCustomer = createAsyncThunk('appCustomers/updateCustomer', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/customers/${params.customer.id}/`, qs.stringify(params.customer))
    .then(async resp => {
      await dispatch(getCustomer(params.customer.id))
      sucessToast("Cliente se modifico correctamente")
      console.log(resp.data)
      return resp.data
    })
    .catch(() => {
      errorToast("Cliente no se modifico correctamente")
    })
})


export const updateCustomerWithoutToast = createAsyncThunk('appCustomers/updateCustomerWithoutToast', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/customers/${params.customer.id}/`, qs.stringify(params.customer))
    .then(async resp => {
      await dispatch(getAllData(params.getData))
      return resp.data
    })
    .catch(() => {
      errorToast("Cliente no se modifico correctamente")
    })
})

export const deleteUser = createAsyncThunk('appCustomers/deleteUser', async (id, { dispatch}) => {
  await axios.delete(`/customers/customers/${id}/`)
  await dispatch(getAllData({page:1, query:""}))
  return id
})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  initialState: {
    allData: [],
    total: 1,
    selectedCustomer: null,
    selectedContact: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        console.log(action)
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
      })

      .addCase(getCustomer.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selectedCustomer = action.payload
      })

      .addCase(getContact.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selectedContact = action.payload
      })

      .addCase(cleanContacts.fulfilled, (state) => {
        state.allData = []
        state.total = 1
      })

  }
})

export default appCustomersSlice.reducer
