// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'


export const getAllTemplates = createAsyncThunk('appTemplates/getAllTemplates', async (params) => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.get(`/customers/templates/`, {params: {p:params.page, search:params.query}})
  return response
})

export const getTemplate = createAsyncThunk('appTemplates/getTemplate', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instance.get(`/customers/templates/${id}/`)
  return response.data
})

export const cleanTemplate = createAsyncThunk('appTemplates/cleanTemplate', () => {
  return "template"
})


export const addTemplate = createAsyncThunk('appTemplates/addTemplate', async (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  instance.post('/customers/templates/', params.template)
    .then(async resp => {
      if (resp.status === 201) {
        console.log(resp)
        await dispatch(getAllTemplates({page:1, search:""}))
        sucessToast("Plantilla fue creada correctamente")
        return resp.data
      } else {
        errorToast("Plantilla no fue creada correctamente")
      }
    })
    .catch((error) => {
      if (error?.response?.data?.error) {
        errorToast(error.response.data.msg)
      } else {
        errorToast("Plantilla no fue creada correctamente")
      }
    })
})

export const updateTemplate = createAsyncThunk('appTemplates/updateTemplate', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 30000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  instance2.put(`/customers/templates/${params.template.id}/`, params.template)
    .then(async resp => {
      await dispatch(getTemplate(params.template.id))
      sucessToast("Plantilla fue modificada correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("Plantilla no fue modificada correctamente")
    })

  
  return template
})


export const deleteTemplate = createAsyncThunk('appTemplates/deleteTemplate', async (id, { dispatch}) => {
  await axios.delete(`/customers/templates/${id}/`)
  await dispatch(getAllTemplates({page:1, search:""}))
  return id
})

export const appTemplateSlice = createSlice({
  name: 'appTemplates',
  initialState: {
    allData: [],
    total: 1,
    selectedTemplate: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        console.log(action)
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
      })

      .addCase(cleanTemplate.fulfilled, (state) => {
        state.allData = []
        state.total = 1
      })

      .addCase(getTemplate.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selectedTemplate = action.payload
      })
  }
})

export default appTemplateSlice.reducer
