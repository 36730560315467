// ** Auth Endpoints
import urls from  './urls'
export default {
  //loginEndpoint: '/jwt/login',

  loginEndpoint: `${urls.url}/login/`,
  
  registerEndpoint: `${urls.url}/users/register/`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refreshToken'
}
